<template>
  <v-row class="getting-started-details mt-6 mt-md-12">
    <v-col class="getting-started-details__menu" cols="12" md="3">
      <Sidebar
        :rootMenu="'getting-started'"
        :items="menuItems"
        :itemChanged="handleSelectedItemChanged"
      />
    </v-col>
    <v-col class="getting-started-details__content" cols="12" md="9">
      <CardItem
        id="installing"
        :title="$t(`support.items.getting-started.menu.installing.text`)"
      >
        <p>
          {{
            $t("support.items.getting-started.menu.installing.content.line-1")
          }}
        </p>
        <p>
          {{
            $t("support.items.getting-started.menu.installing.content.line-2")
          }}
        </p>
        <ul>
          <li class="mb-4">
            {{
              $t("support.items.getting-started.menu.installing.content.line-3")
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.installing.content.line-4"
              )
            }}:
          </li>
        </ul>
        <p class="text-decoration-underline">
          {{
            $t("support.items.getting-started.menu.installing.content.line-5")
          }}:
          <a
            :href="
              $t('support.items.getting-started.menu.installing.content.line-6')
            "
            >{{
              $t("support.items.getting-started.menu.installing.content.line-6")
            }}
          </a>
        </p>
      </CardItem>
      <CardItem
        id="new-account"
        :title="$t(`support.items.getting-started.menu.new-account.text`)"
      >
        <p>
          {{
            $t("support.items.getting-started.menu.new-account.content.line-1")
          }}
        </p>
        <ul>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-2"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-3"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-4"
              )
            }}
          </li>
        </ul>
        <p>
          {{
            $t("support.items.getting-started.menu.new-account.content.line-5")
          }}:
        </p>
        <ol>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-6"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-7"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-8"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-9"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-10"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-11"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-12"
              )
            }}
          </li>
        </ol>
        <ul>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-13"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-14"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-15"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-16"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-17"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-18"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-19"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.new-account.content.line-20"
              )
            }}
          </li>
        </ul>
      </CardItem>
      <CardItem
        id="publish-feedback"
        :title="$t(`support.items.getting-started.menu.publish-feedback.text`)"
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.publish-feedback.content.line-1"
            )
          }}
        </p>
        <ul>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.publish-feedback.content.line-2"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.publish-feedback.content.line-3"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.publish-feedback.content.line-4"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.publish-feedback.content.line-5"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.publish-feedback.content.line-6"
              )
            }}
          </li>
        </ul>
        <p>
          {{
            $t(
              "support.items.getting-started.menu.publish-feedback.content.line-7"
            )
          }}
        </p>
      </CardItem>
      <CardItem
        id="search-feedback"
        :title="$t(`support.items.getting-started.menu.search-feedback.text`)"
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.search-feedback.content.line-1"
            )
          }}
        </p>
      </CardItem>
      <CardItem
        id="find-friend"
        :title="$t(`support.items.getting-started.menu.find-friend.text`)"
      >
        <p>
          {{
            $t("support.items.getting-started.menu.find-friend.content.line-1")
          }}
        </p>
      </CardItem>
      <CardItem
        id="last-feedbacks"
        :title="$t(`support.items.getting-started.menu.last-feedbacks.text`)"
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.last-feedbacks.content.line-1"
            )
          }}
        </p>
      </CardItem>
      <CardItem
        id="report-feedback"
        :title="$t(`support.items.getting-started.menu.report-feedback.text`)"
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.report-feedback.content.line-1"
            )
          }}
        </p>
      </CardItem>
      <CardItem
        id="find-location"
        :title="$t(`support.items.getting-started.menu.find-location.text`)"
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.find-location.content.line-1"
            )
          }}
        </p>
      </CardItem>
      <CardItem
        id="password-requirements"
        :title="
          $t(`support.items.getting-started.menu.password-requirements.text`)
        "
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.password-requirements.content.line-1"
            )
          }}
        </p>
      </CardItem>
      <CardItem
        id="reset-password"
        :title="$t(`support.items.getting-started.menu.reset-password.text`)"
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.reset-password.content.line-1"
            )
          }}
        </p>
      </CardItem>
      <CardItem
        id="requests-types"
        :title="$t(`support.items.getting-started.menu.requests-types.text`)"
      >
        <p>
          {{
            $t(
              "support.items.getting-started.menu.requests-types.content.line-1"
            )
          }}
        </p>
        <ol>
          <li>
            {{
              $t(
                "support.items.getting-started.menu.requests-types.content.line-2"
              )
            }}
          </li>
          <li class="mb-4">
            {{
              $t(
                "support.items.getting-started.menu.requests-types.content.line-3"
              )
            }}
          </li>
        </ol>
        <p>
          {{
            $t(
              "support.items.getting-started.menu.requests-types.content.line-4"
            )
          }}
        </p>
        <p>
          {{
            $t(
              "support.items.getting-started.menu.requests-types.content.line-5"
            )
          }}
        </p>
      </CardItem>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    Sidebar: () => import("../Shared/Sidebar.vue"),
    CardItem: () => import("../Shared/CardItem.vue"),
  },
  data(vm) {
    return {
      menuItems: [
        {
          key: "installing",
          value: vm.$t("support.items.getting-started.menu.installing.text"),
        },
        {
          key: "new-account",
          value: vm.$t("support.items.getting-started.menu.new-account.text"),
        },
        {
          key: "publish-feedback",
          value: vm.$t(
            "support.items.getting-started.menu.publish-feedback.text"
          ),
        },
        {
          key: "search-feedback",
          value: vm.$t(
            "support.items.getting-started.menu.search-feedback.text"
          ),
        },
        {
          key: "find-friend",
          value: vm.$t("support.items.getting-started.menu.find-friend.text"),
        },
        {
          key: "last-feedbacks",
          value: vm.$t(
            "support.items.getting-started.menu.last-feedbacks.text"
          ),
        },
        {
          key: "report-feedback",
          value: vm.$t(
            "support.items.getting-started.menu.report-feedback.text"
          ),
        },
        {
          key: "find-location",
          value: vm.$t("support.items.getting-started.menu.find-location.text"),
        },
        {
          key: "password-requirements",
          value: vm.$t(
            "support.items.getting-started.menu.password-requirements.text"
          ),
        },
        {
          key: "reset-password",
          value: vm.$t(
            "support.items.getting-started.menu.reset-password.text"
          ),
        },
        {
          key: "requests-types",
          value: vm.$t(
            "support.items.getting-started.menu.requests-types.text"
          ),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      searchParams: "SearchParams/getSearchParams",
    }),
  },
  watch: {
    searchParams: {
      handler({ criteria }) {
        if (criteria) {
          const menuItem = this.menuItems.find(
            (item) => item.value === criteria
          );

          if (menuItem) this.handleSelectedItemChanged(menuItem.key);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSelectedItemChanged(key) {
      this.$vuetify.goTo(`#${key}`);
    },
  },
};
</script>
